html,
body,
#root {
  height: 100%;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: #fdfdfd;
  -webkit-font-smoothing: antialiased;
}

main {
  height: 100%;
  display: flex;
}
